$country-codes = 'au' 'at' 'br' 'ca' 'ch' 'de' 'dk' 'es' 'fr' 'gb' 'ie' 'in' 'my' 'nl' 'nz' 'us'

.fdw-country-flag
  position relative
  display inline-block
  width 1.33333333em
  background-size contain
  background-position 50%
  background-repeat no-repeat
  line-height 1em
  text-indent -9999px

for $code, i in $country-codes
  .fdw-country-flag-{$code}
    background-image url('flag-icons/flags/4x3/' + $code + '.svg')
