@import '@src/mixins'
@import '@src/vars'

.fdw-company-list
  li:not(:first-child)
    border-top 1px solid $color-grey-darker
  details
    .fdw-company-list-item-detail-summary
      position relative
      &:after
        content ''
        position absolute
        top calc(50% - 6px)
        right 16px
        width 8px
        height 8px
        border-color $color-grey-dark
        border-style solid
        border-width 0 1px 1px 0
        transform rotate(45deg)
        transform-origin 5px 5px
        transition all 500ms cubic-bezier(0.68, -0.55, 0.27, 1.55)
    &[open]
      .fdw-company-list-item-detail-summary
        &:after
          border-color $color-grey
          transform rotate(-135deg)

.fdw-company-list-item-detail-summary
  display flex
  justify-content space-between
  align-items center
  padding 20px 45px 20px 5px
  list-style none
  &::marker,
  &::-webkit-details-marker
    display none
  .fdw-company-origin
    margin-top 5px
    font-size $font-size-xxs
    opacity .5

.fdw-company-list-item-detail-adoption
  padding-right 10px
  color $color-lightest

.fdw-company-list-item-detail-adoption-label
  color $color-grey-dark
  font-weight bold
  font-size $font-size-xxxs
  text-transform uppercase

.fdw-company-list-item-detail-content
  padding 5px 5px 20px
  footer
    margin-top 10px
    a + a
      margin-left 10px

+breakpoint('md')
  .fdw-company-list-item-detail-summary
    cursor pointer
    transition background-color 300ms ease-out
    &:hover
      background-color alpha($color-grey-darker, .25)

  .fdw-company-list-item-detail-adoption
    padding-right 50px
