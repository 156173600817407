@import '@src/vars'

.fdw-collaborators-view
  .fdw-collaborators-view-list
    margin-top 40px
    li
      display flex
      justify-content space-between
      align-items center
      padding 20px 5px
      color $color-lightest
      &:not(:first-child)
        border-top 1px solid $color-grey-darker
