@import '@src/vars'
@import '@src/mixins'

$footer-height = 100px

.fdw-view
  position relative
  margin 0 auto
  padding-bottom $footer-height + 80px
  width 90%
  min-height 100vh
  max-width 600px
  box-sizing border-box

.fdw-view-footer
  position absolute
  bottom 0
  left 0
  width 100%
  height $footer-height
  color $color-grey-dark
  font-size $font-size-xxs
  a
    text-transform none

.fdw-view-footer-credits
  a
    color $color-grey-dark
    font-weight normal
    text-decoration underline

+breakpoint('sm')
  .fdw-view-footer
    display flex
    align-items center
    justify-content center
    flex-direction column
