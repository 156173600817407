@import '@src/vars'

.fdw-home-view
  position relative
  h2
    position sticky
    top 0
    left 0
    display flex
    align-items center
    justify-content space-between
    margin-top 30px
    padding 30px 0
    background $color-darkest
    background linear-gradient(180deg, rgba(0,0,0, 1) 10%, rgba(0,0,0,0.8) 75%, rgba(0,0,0,0) 100%);
    color $color-grey-dark
    font-size $font-size-xs
    font-weight 600
    text-transform uppercase
    z-index $layer-lowest
  .fdw-company-list-wrapper
    margin-top 20px

.fdw-home-view-company-sort-select
  margin-left 5px
  padding 0
  background-color transparent
  color $color-grey-lighter
  font-weight bold
  text-align center
  text-transform uppercase
  border-width 0 0 1px 0
  border-style dotted
  border-color $color-grey-lighter
  cursor pointer
  -webkit-appearance none
  option
    background-color $color-darkest
