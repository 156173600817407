@import '@src/vars'

.fdw-link
  color $color-purple
  font-size $font-size-xxs
  font-weight 600
  text-transform uppercase
  text-decoration none
  &:hover,
  &:focus
    text-decoration underline
